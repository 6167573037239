import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function Banner() {
  return (
    <div className="banner-section">
      {/* <Container fluid>
        <Row> */}
      <p className="banner-font">CURRENTLY ONLY OFFERING TELEHEALTH</p>
      {/* </Row>
      </Container> */}
    </div>
  );
}
